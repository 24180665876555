<template>
  <div class="item-list">
    <p class="p1"><span class="span1">{{item.categoryName}}</span><span>待接单</span></p>
    <p>订单号: <span>{{item.orderNo}}</span></p>
    <p v-if="item.appointmentBeginTime">预约收运时间: <span>{{item.appointmentBeginTime}}-{{item.appointmentEndTime.substring(11)}}</span></p>
    <p>下单人: <span>{{item.trueName}}</span></p>
    <p>地址: <span>{{item.orderAddress}}</span></p>
    <div class="btns">
      <van-button type="default" size="small" @click="delClick(item.id)">取消</van-button>
      <van-button type="default" size="small" @click="details(item.id)">查看订单</van-button>
    </div>
  </div>
</template>

<script>
import { delorder } from '@/api/order.js'
import { Toast } from 'vant'
import { Dialog } from 'vant'
export default {
  name: 'MyOrderDjd',
  props: [
    'item'
  ],
  watch: {
    item(newval, oldval) {
      // console.log(newval)
    }
  },
  data () {
    return {
    }
  },
  created() {
  },
  methods: {
    delClick(id) { // 取消订单
      Dialog.confirm({
        title: '删除订单',
        message: '确定删除此条订单？',
      }).then(() => {
        delorder(id).then(res => {
          if (res.data.code === 200) {
            Toast('订单删除成功')
            location.reload()
          }
        })
      }).catch(() => {
        
      })
    },
    details(id) { // 详情
      this.$router.push({path: 'Mydetails', query: {id: id}})
    }
  }
}

</script>
<style lang="scss" scoped>
.item-list {
  margin: 7px 0;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  .p1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .span1 {
      color: #333;
      font-weight: 600;
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 3px;
    color: #999;
    font-size: 16px;
    span {
      color: #333;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
::v-deep .van-button--small {
  margin-right: 5px;
}
</style>
