<template>
  <div class="item-list">
    <p class="p1"><span class="span1">{{item.categoryName}}</span><span>待上门收运</span></p>
    <p>订单号: <span>{{item.orderNo}}</span></p>
    <p>接单时间: <span>{{conversionTime(item.takingTime)}}</span></p>
    <p>下单人: <span>{{item.trueName}}</span></p>
    <p>收运员: <span>{{item.runnerName}}</span></p>
    <div class="btns">
      <van-button plain type="default" size="small" @click="queryCLick(item)">查看距离</van-button>
      <van-button type="default" size="small" @click="details(item.id)">查看订单</van-button>
    </div>
  </div>
</template>

<script>
import { jsapiSignature, getLocation } from '@/api/notice.js'
export default {
  name: 'MyOrderDsm',
  props: [
    'item'
  ],
  watch: {
    item(newval, oldval) {
      // console.log(newval)
    }
  },
  data () {
    return {
      runnerLatitude: undefined,
      runnerLongitude: undefined
    }
  },
  created() {
    // this.jsapiSignature()
  },
  methods: {
    jsapiSignature() { // 获取jsapi
      let hrefurl = sessionStorage.getItem("url")
      jsapiSignature({url: hrefurl}).then(res => {
        wx.config({
          beta: true,
          debug: false, // 开启调试模式,
          appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录1
          jsApiList: ['openLocation', 'getLocation'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.checkJsApi({
          jsApiList: ['openLocation', 'getLocation'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
          },
          fail: function (res) {
          }
        })
        wx.ready(() => {
          const latitudee = this.runnerLatitude
          const longitudee = this.runnerLongitude
          wx.openLocation({
            type: 'gcj02',
            latitude: parseFloat(latitudee), // 纬度，浮点数，范围为90 ~ -90
            longitude: parseFloat(longitudee), // 经度，浮点数，范围为180 ~ -180。
            name: '清运员位置', // 位置名
            address: '清运员详细地址可直接导航', // 地址详情说明
            scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
          })
        })
      })
    },
    queryCLick(item) { // 获取回收员地理位置
      getLocation(item.runnerId).then(res => {
        if(res.data.code === 200) {
          this.runnerLatitude = res.data.data.latitude
          this.runnerLongitude = res.data.data.longitude
          if(this.runnerLatitude === null || this.runnerLongitude === null) {
            this.runnerLatitude = Number(30.27)
            this.runnerLongitude = Number(120.13)
          }
          this.jsapiSignature() // 调用jsapi信息
        }
      })
    },
    details(id) { // 详情
      this.$router.push({path: 'Mydetails', query: {id: id}})
    }
  }
}

</script>
<style lang="scss" scoped>
.item-list {
  margin: 7px 0;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  .p1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .span1 {
      color: #333;
      font-weight: 600;
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 3px;
    color: #999;
    font-size: 16px;
    span {
      color: #333;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
::v-deep .van-button--small {
  margin-right: 5px;
}
</style>
