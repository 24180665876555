<template>
  <div class="item-list">
    <p class="p1"><span class="span1">{{item.categoryName}}</span><span>已完成</span></p>
    <p>订单号: <span>{{item.orderNo}}</span></p>
    <p>收运时间: <span>{{conversionTime(item.finishTime)}}</span></p>
    <p>下单人: <span>{{item.trueName}}</span></p>
    <p>收运员: <span>{{item.runnerName}}</span></p>
    <div class="btns">
      <van-button type="default" size="small" @click="details(item.id)">查看订单</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyOrderDsm',
  props: [
    'item'
  ],
  watch: {
    item(newval, oldval) {
      // console.log(newval)
    }
  },
  data () {
    return {
    }
  },
  methods: {
    details(id) { // 详情
      this.$router.push({path: 'Mydetails', query: {id: id}})
    }
  }
}

</script>
<style lang="scss" scoped>
.item-list {
  margin: 7px 0;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  .p1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .span1 {
      color: #333;
      font-weight: 600;
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 3px;
    color: #999;
    font-size: 16px;
    span {
      color: #333;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
::v-deep .van-button--small {
  margin-right: 5px;
}
</style>
