<template>
  <div class="warp">
    <van-tabs v-model="activeBQ" @click="onClick">
      <van-tab title="全部">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad">
          <div v-for="(item, index) in itemList" :key="index">
            <div v-if="item.orderStatus === 1">
              <my-order-djd :item="item" :key="index"></my-order-djd>
            </div>
            <div v-if="item.orderStatus === 2">
              <my-order-dsm :item="item" :key="index"></my-order-dsm>
            </div>
            <div v-if="item.orderStatus >= 3 && item.orderStatus <= 5">
              <my-order-ywc :item="item" :key="index"></my-order-ywc>
            </div>
          </div>
        </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="待接单">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad">
          <div v-for="(item, index) in itemList" :key="index">
            <my-order-djd :item="item" :key="index"></my-order-djd>
          </div>
        </van-list>
      </van-pull-refresh>
      </van-tab>
      <van-tab title="待上门收运">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad">
            <div v-for="(item, index) in itemList" :key="index">
              <my-order-dsm :item="item" :key="index"></my-order-dsm>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已完成">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad">
            <div v-for="(item, index) in itemList" :key="index">
              <my-order-ywc :item="item" :key="index"></my-order-ywc>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <main-tab-bar></main-tab-bar>
  </div>
</template>

<script>
import { orderList } from '@/api/order.js'
import MyOrderDjd from '../my/myorder/Myorderdjd.vue'
import MyOrderDsm from '../my/myorder/Myorderdsm.vue'
import MyOrderYwc from '../my/myorder/Myorderywc.vue'
import MainTabBar from '@/components/assembly/Maintabbar.vue'
export default {
  components: {
    MyOrderDjd,
    MyOrderDsm,
    MyOrderYwc,
    MainTabBar
  },
  data () {
    return {
      activeBQ: 1,
      loading: false, // 上拉加载状态
      finished: false, // 是否已加载完成，加载完成后不再触发load事件进去多次加载请求
      refreshing: false, // 下拉刷新状态
      query: {
        orderStatus: 1,
        pageNum: 1,
        pageSize: 10
      },
      total: undefined,
      scrollTop: undefined,
      itemList: [], // 保存订单lsit
    }
  },
  created() {
    if (this.$route.query.actives) {
      this.query.orderStatus = this.$route.query.actives
      this.activeBQ = parseInt(this.$route.query.actives)
    }
  },
  activated() {
    this.itemList = []
    this.orderList()
  },
  methods: {
    orderList() { // 获取默认显示待接单
      orderList(this.query).then(res => {
        if(res.data.code === 200) {
          if (this.itemList.length) {
            if (this.loading) { // 上拉加载
              if (res.data.rows.length < 10) { // 没有更多数据
                this.finished = true // 表示上拉加载完毕
              }
              this.itemList = this.itemList.concat(res.data.rows) // 上拉加载新数据添加到数组中
              this.$nextTick(() => { // 在下次 DOM 更新循环结束之后执行延迟回调
                this.loading = false // 关闭上拉加载中
              })
            }
            if (this.refreshing) { // 关闭下拉刷新
              this.refreshing = false // 关闭下拉刷新
              this.itemList = res.data.rows // 重新给数据赋值
            }
          } else {
            this.finished = false
            this.loading = false
            this.refreshing = false
            this.itemList = res.data.rows
          }
          this.total = res.data.total
        }
      })
    },
    onClick(name) { // 点击tab标签
      this.itemList = []
      this.query.pageNum = 1
      this.query.orderStatus = name
      this.orderList()
    },
    onLoad() { // 上拉加载
      setTimeout(() => {
        if (this.itemList.length < this.total) {
          this.loading = true
          this.query.pageNum++
          this.orderList()
        } else {
          this.finished = true
        }
      }, 300)
    },
    onRefresh() { // 下拉刷新
      setTimeout(() => {
        // 下拉刷新后，可以重新触发上拉加载
        this.finished = false
        // 将 refreshing 设置为 true，表示处于刷新状态
        this.refreshing = true
        this.query.pageNum = 1
        this.orderList()
      }, 300)
    },
  }
}

</script>
<style lang="scss" scoped>
::v-deep .van-tabs__content {
  height: calc(100vh - 95px);
  overflow: scroll;
}
::v-deep .van-tabs__line {
  background-color: #079ef2;
}
::v-deep .van-tab__pane, .van-tab__pane-wrapper {
  height: 100%;
}
::v-deep .van-pull-refresh {
  height: 100%;
  overflow: scroll;
}
</style>
